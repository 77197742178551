import React, { useState } from "react";
import Candle from "./Candle";
import "./App.css"; // Make sure the CSS is properly linked

function App() {
  const [labels, setLabels] = useState({ candle1: "", candle2: "" });
  const [started, setStarted] = useState(false);
  const [winner, setWinner] = useState("");

  const randomBurnRate = () => Math.random() * 5 + 1; // Random burn rate between 1 and 6

  const handleStart = () => {
    setStarted(true);
    setWinner("");
  };

  const handleReset = () => {
    setStarted(false);
    setLabels({ candle1: "", candle2: "" });
    setWinner("");
  };

  const handleBurnOut = (label) => {
    if (!winner) setWinner(Object.values(labels).filter((l) => l !== label)[0]);
  };

  return (
    <div className="App">
      <form className="inputs-container">
        <input
          type="text"
          className="choice"
          placeholder="Label for Candle 1"
          value={labels.candle1}
          onChange={(e) => setLabels({ ...labels, candle1: e.target.value })}
          disabled={started}
        />
        <input
          type="text"
          className="choice"
          placeholder="Label for Candle 2"
          value={labels.candle2}
          onChange={(e) => setLabels({ ...labels, candle2: e.target.value })}
          disabled={started}
        />
        <button type="submit" onClick={handleStart} disabled={started}>
          Decide
        </button>
        <button onClick={handleReset}>Reset</button>
      </form>

      {started && (
        <div className="candles-container">
          <Candle
            label={labels.candle1}
            burnRate={randomBurnRate}
            onBurnOut={handleBurnOut}
            winner={started && winner === labels.candle1}
          />
          <Candle
            label={labels.candle2}
            burnRate={randomBurnRate}
            onBurnOut={handleBurnOut}
            winner={started && winner === labels.candle2}
          />
        </div>
      )}
    </div>
  );
}

export default App;
