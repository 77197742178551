import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./Candle.css"; // Make sure the CSS is properly linked

const CANDLE_HEIGHT = 300; // Candle height in pixels
const MIN_CANDLE_HEIGHT = 50; // Minimum candle height in pixels

const Candle = ({ label, burnRate, onBurnOut, winner }) => {
  const [height, setHeight] = useState(100); // Represents the candle height as a percentage

  useEffect(() => {
    const interval = setInterval(() => {
      setHeight((prev) => {
        const newHeight = prev - burnRate();
        if (newHeight <= 0) {
          clearInterval(interval);
          onBurnOut(label);
          return 0;
        } else if (winner) {
          clearInterval(interval);
          return prev;
        }
        return newHeight;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [burnRate, label, onBurnOut, winner]);

  const waxStyle = {
    height: `${(height / 100) * CANDLE_HEIGHT + MIN_CANDLE_HEIGHT}px`,
  };

  return (
    <div className="candle-container">
      <div className="candle">
        <div
          className={classNames("flame", {
            hidden: height <= 0 && !winner,
          })}
        >
          <div className="shadows"></div>
          <div className="top"></div>
          <div className="middle"></div>
          <div className="bottom"></div>
        </div>
        <div className="wick"></div>
        <div className="wax" style={waxStyle}></div>
      </div>
      <div className={winner ? "winner" : ""}>
        <p className="label">{label}</p> {/* Candle label */}
      </div>
    </div>
  );
};

export default Candle;
